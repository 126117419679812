<script lang="jsx">
import userSalary from '@/components/user-salary/index.vue'

const Fcommission = Object.assign({}, userSalary, {
  data () {
    return Object.assign({}, userSalary.data(), {
      pageType: 2,
      isServe: true,
      pageTitle: '用工佣金管理'
    })
  }
})

export default {
  mixins: [Fcommission]
}
</script>
